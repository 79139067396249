import Logo from '../assets/logo.png'

import React from 'react';
import { Container, Row, Col, Image } from 'react-bootstrap';
import { ConnectButton } from '@rainbow-me/rainbowkit';

export function Header() {

  return (<>
    <div className="text-secondary px-4 text-center">
      <div className="py-4">
        <Image src={Logo} width="100" className="d-block mx-auto" alt="The Cryptomasks Project" />
        <h1 className="display-5 fw-bold text-white">The Cryptomasks Project</h1>
        <h2>💀 Maskalavera 💀</h2>
        <Container>
          <Row className="justify-content-center">
            <Col lg={6}>
              <div className="d-grid gap-2 d-sm-flex justify-content-sm-center">
                <div className="connect-zone"><ConnectButton /></div>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
    </>)
}